.container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start */
  margin: 20px;
  column-gap: 5px;
}

.input-form {
  flex: 0 0 auto;
  max-width: 30%; /* Adjust width */
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc; /* Keep border around the input form */
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px; /* Increased margin for better spacing */
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold; /* Emphasize labels */
}

input {
  width: 100%;
  padding: 10px; /* Increased padding for better UX */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

button:hover {
  background-color: #0056b3;
  color: #fff;
}

.response-container {
  flex: 2;
  margin-right: 20px;
  padding: 20px;
  overflow-y: auto;
  background-color: #ffffff; /* Remove border and keep background color */
  overflow-x: auto; /* Enable horizontal scrolling */
  max-height: 85vh;
  position: relative; /* Create a new stacking context */
  clip-path: inset(0 0 0 20px); /* Clip the content inside the container */
}

.error {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffe6e6;
  color: red;
  padding: 10px;
  border: 1px solid red;
  border-radius: 5px;
  font-weight: bold;
  z-index: 1000;
  animation: fadeOut 5s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

.table-wrapper {
  overflow-x: auto;
  margin-bottom: 0; /* Optional: Add spacing between tables */
}


/* Button Styling */
.button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.reset-button {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #666;
  font-size: 16px;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
}

.reset-button i {
  font-size: 20px;
}

.reset-button:hover {
  background-color: #f0f0f0;
  color: #333;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive Styling for Mobile Devices */
@media only screen and (max-width: 1122px) {
  .container {
    flex-direction: column;
    align-items: stretch;
    display: block;
  }

  .input-form {
    max-width: 100%;
    width: 100%;
    margin: 10px 0; /* Remove margins to prevent horizontal overflow */
    padding: 10px 10px; /* Optional: Add side padding if needed */
    box-sizing: border-box; /* Include padding and border in width calculation */
  }

  .response-container {
    max-width: 100%;
    width: 100%;
    margin: 0;
    align-content: center;
    overflow-y: scroll;
    max-height: none;
    position: static;
    padding: 1px;       /* Remove padding */
    clip-path: none;    /* Remove clip-path */
  }

  .table-wrapper {
    overflow-x: auto;
    margin-top: 20px;
  }
}